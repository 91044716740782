.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  cursor: pointer;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  cursor: not-allowed;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  cursor: not-allowed;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

[data-background="light"] {
  .rdtPicker {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid theme("colors.gray.50");
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: theme("colors.gray.200");
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: theme("colors.gray.500");
  }
  .rdtPicker td.rdtToday:before {
    border-bottom: 7px solid theme("colors.blue.500");
    border-top-color: rgba(0, 0, 0, 0.2);
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: theme("colors.blue.500");
    color: #fff;
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    color: theme("colors.gray.500");
  }

  .rdtPicker td span.rdtOld {
    color: theme("colors.gray.500");
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    color: theme("colors.gray.500");
  }
  .rdtPicker th {
    border-bottom: 1px solid theme("colors.gray.50");
  }
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    color: theme("colors.gray.500");
  }
  .rdtPicker thead tr:first-child th:hover {
    background: theme("colors.gray.200");
  }
  .rdtPicker tfoot {
    border-top: 1px solid theme("colors.gray.50");
  }
  .rdtPicker button:hover {
    background-color: theme("colors.gray.200");
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: theme("colors.gray.200");
  }
  .rdtCounter .rdtBtn .rdtCounter .rdtBtn:hover {
    background: theme("colors.gray.200");
  }
}

[data-background="dark"] {
  .rdtPicker {
    background: theme("colors.gray.800");
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid theme("colors.gray.700");
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: theme("colors.gray.600");
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: theme("colors.gray.700");
  }
  .rdtPicker td.rdtToday:before {
    border-bottom: 7px solid theme("colors.blue.500");
    border-top-color: rgba(0, 0, 0, 0.2);
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: theme("colors.blue.500");
    color: #fff;
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: theme("colors.gray.800");
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    color: theme("colors.gray.700");
  }

  .rdtPicker td span.rdtOld {
    color: theme("colors.gray.700");
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    color: theme("colors.gray.700");
  }
  .rdtPicker th {
    border-bottom: 1px solid theme("colors.gray.700");
  }
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    color: theme("colors.gray.700");
  }
  .rdtPicker thead tr:first-child th:hover {
    background: theme("colors.gray.600");
  }
  .rdtPicker tfoot {
    border-top: 1px solid theme("colors.gray.700");
  }
  .rdtPicker button:hover {
    background-color: theme("colors.gray.600");
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: theme("colors.gray.600");
  }
  .rdtCounter .rdtBtn .rdtCounter .rdtBtn:hover {
    background: theme("colors.gray.600");
  }
}

