[data-navbar="light"] {
  .app-navbar {
    @apply text-gray-900;
    @apply bg-white border-gray-100;
    .navbar-search {
      input {
      @apply bg-gray-100 border-gray-100;
      }
    }
  }
}

[data-navbar="dark"] {
  .app-navbar {
    @apply text-white;
    background: color(theme('colors.gray.900') tint(5%));
    border-color: color(theme('colors.gray.900') tint(10%));
    .navbar-search {
      input {
        background: color(theme('colors.gray.900') tint(10%));
        border-color: color(theme('colors.gray.900') tint(10%));
      }
    }
  }
}
